import React from 'react'
import Button from '../components/Buttons/Button'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout language="en">
    <SEO
      title="404 Error | New Healthcare Media"
      description="404 page not found."
      robots="noindex"
    />

    <div className="section nav-spacer">
      <div className="container medium page-not-found">
        <h1>404: Page not found</h1>
        <h2>Looks like this page is lost in the stars.</h2>
        <p>
          It looks like the page you are looking for is missing or no longer
          exists. Please check the URL to make sure it’s correct, or go back to
          the homepage.
        </p>
        <Button
          href="/"
          buttonText="Home"
          internal
          appearance="outline-green"
        />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
